<template>
    <div class="h-auto flex flex-row justify-start items-center">
        <div class="h-full w-9/12 flex flex-row justify-start items-center">
            <span class="text-white font-semibold text-xs mr-auto">Lost</span>
        </div>

        <div class="h-full w-2/12 flex flex-row justify-end items-center">
            <span class="text-white text-xs mr-2">{{ row.total.oportunity | numberFormat(0) }}</span>
        </div>

        <div class="h-full w-3/12 flex flex-row justify-center items-center">
            <span class="text-font-gray text-xxs">{{ row.total.sales | reduceBigNumbers() }} €</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TotalsLost",
        props: ['row']
    }
</script>